import React from "react";
import './Banner.scss';
import banner from "../../../assets/images/banner.jpg"
class Banner extends React.Component{
    render(){
        return <>
            <div className="banner-product">
                <div className="col-xxl-12 d-flex justify-content-center align-items-center">
                    <img src={banner}  alt="banner" className="centered-img"/>
                </div>
            </div>
        </>
    }
}

export default Banner;