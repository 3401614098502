import React from "react";
import './ListProduct.scss';
import item1 from "../../../assets/images/items/item_1.jpg"
import item2 from "../../../assets/images/items/item_2.jpg"
import item3 from "../../../assets/images/items/item_3.jpg"
import item4 from "../../../assets/images/items/item_4.jpg"
import item5 from "../../../assets/images/items/item_5.jpg"
import item6 from "../../../assets/images/items/item_6.jpg"
import item7 from "../../../assets/images/items/item_7.jpg"
import item8 from "../../../assets/images/items/item_8.jpg"

class ListProduct extends React.Component{
    render(){
        return <>
             <div className="container-fluid row list-product d-flex justify-content-center align-items-center m-0 p-0">
                <div className="content-wrapper col-xxl-12">
                    <h1 className="trirong-regular title-0 text-center">SẢN PHẨM</h1>
                </div>

                <div className="col-xxl-12 col-12 row product">
                    <div className="col-xxl-3 col-6 d-flex justify-content-center align-items-center">
                        <div className="item">
                            <div className="image-item">
                                <img src={item1} alt="item1" className="image" />
                            </div>
                            <div className="col-xxl-12 col-12 name-item">
                                <p className="trirong-regular m-0">Khăn ướt diệt khuẩn</p>
                                <div className="hover-item">
                                    <p className="trirong-regular">Xem thêm</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-6 d-flex justify-content-center align-items-center">
                        <div className="item">
                            <img src={item2} alt="item3" className="image-item" />
                            <div className="col-xxl-12 name-item">
                                <p className="trirong-regular">Khăn ướt không mùi</p>
                                <div className="hover-item">
                                    <p className="trirong-regular">Xem thêm</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-6 d-flex justify-content-center align-items-center">
                        <div className="item">
                            <img src={item3} alt="item3" className="image-item" />
                            <div className="col-xxl-12 name-item">
                                <p className="trirong-regular">Khăn ướt thơm</p>
                                <div className="hover-item">
                                    <p className="trirong-regular">Xem thêm</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xxl-3 col-6 d-flex justify-content-center align-items-center">
                        <div className="item">
                            <img src={item4} alt="item4" className="image-item" />
                            <div className="col-xxl-12 name-item">
                                <p className="trirong-regular">Khăn ướt baby</p>
                                <div className="hover-item">
                                    <p className="trirong-regular">Xem thêm</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-xxl-12 col-12 row product">
                    <div className="col-xxl-3 col-6 d-flex justify-content-center align-items-center">
                        <div className="item">
                            <div className="image-item">
                                <img src={item5} alt="item5" className="image" />
                            </div>
                            <div className="col-xxl-12 col-12 name-item">
                                <p className="trirong-regular m-0">Combo khăn ướt diệt khuẩn</p>
                                <div className="hover-item">
                                    <p className="trirong-regular">Xem thêm</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-6 d-flex justify-content-center align-items-center">
                        <div className="item">
                            <img src={item6} alt="item6" className="image-item" />
                            <div className="col-xxl-12 name-item">
                                <p className="trirong-regular">Combo khăn ướt không mùi</p>
                                <div className="hover-item">
                                    <p className="trirong-regular">Xem thêm</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-6 d-flex justify-content-center align-items-center">
                        <div className="item">
                            <img src={item7} alt="item7" className="image-item" />
                            <div className="col-xxl-12 name-item">
                                <p className="trirong-regular">Combo khăn ướt thơm</p>
                                <div className="hover-item">
                                    <p className="trirong-regular">Xem thêm</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xxl-3 col-6 d-flex justify-content-center align-items-center">
                        <div className="item">
                            <img src={item8} alt="item8" className="image-item" />
                            <div className="col-xxl-12 name-item">
                                <p className="trirong-regular">Combo khăn ướt baby</p>
                                <div className="hover-item">
                                    <p className="trirong-regular">Xem thêm</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                

             </div>
        </>
    }
}

export default ListProduct;