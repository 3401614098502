import React from "react";
import axios from 'axios'
import { Link } from "react-router-dom";
import SlideBarAdmin from "../../SlidebarAdmin/SlideBArAdmin";
import NavBarAdmin from "../../NavbarAdmin/NavbarAdmin";
class ProductAdmin extends React.Component{
    state = {
        data : null
    }


    componentDidMount(){
        //const item = [...this.state.data];
        axios.get("http://localhost:8081/")
        .then(res => {
            this.setState({data: res.data})            
        })
        .catch(err => console.log(err));
    }



    handleClickDelete = (e, id) =>{
        var result = window.confirm('Bạn có chắc chắn muốn xóa sản phẩm này?');
        if (result) {
            axios.delete("http://localhost:8081/deleteProduct/" + id)
            .then(res => {
                const alert = document.getElementById('alertDeleteSuccess');
                alert.classList.remove("d-none");

                const row = e.target.closest('tr');
                row.classList.add("d-none");
            })
            .catch(err => console.log(err));
        } 
    }
    
    render(){
        const {data} = this.state;
        return <div className="container-fluid">
        <div className="row">
            <SlideBarAdmin />
            <div className="col-md-10 px-0 ">
                <NavBarAdmin />
                <div className="container-fluid mt-4">
                    <div className="row">
                        <div className="col-md-9">
                            <h2>Sản phẩm</h2>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex justify-content-end">
                                <Link to="/admin/create_product" className="btn btn-success">Create</Link>
                            </div>
                        </div>
                    </div>

                    <div class="alert alert-danger d-none" id="alertDeleteSuccess" role="alert">
                        Xóa thành công
                    </div>

                        <table className="table table-striped mt-2">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">#</th>
                                    <th scope="col" className="text-center">Sản phẩm</th>
                                    <th scope="col" className="text-center">Ảnh</th>
                                    <th scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((item, index) =>{
                                    return (
                                        <tr key={index}>
                                            <th scope="row" className="text-center align-middle">{item.id}</th>
                                            <td className="text-center align-middle ">{item.title}</td>
                                            <td className="text-center">
                                                <img src={item.url} width="250px" height="150px" alt="anh"/>                                               
                                            </td>
                                            <td className="text-center align-middle">
                                                <Link to={`/admin/update_product/${item.id}`} className="btn btn-primary mx-1">Edit</Link>
                                                <button onClick={(e) => this.handleClickDelete(e, item.id)} className="btn btn-danger mx-1">Delete</button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        

    }
}

export default ProductAdmin;