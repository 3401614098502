import React from "react";
import './Header.scss';
import { Link } from "react-router-dom";

class Header extends React.Component{
  render(){
    return <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light header p-0">
            <div className="container-fluid">
                    <div className="col-xxl-4 col-10 d-flex justify-content-center align-items-center">
                        <p className="name-company d-flex justify-content-center align-items-center" >Khăn ước Chiêu Dương</p>
                    </div>
                    <div className="col-xxl-4 col-2">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                          </button>
                    </div>

                    <div className="collapse navbar-collapse col-xxl-4 row end-0 right-div" id="navbarNav">
                      <ul className="navbar-nav position-absolute ul-menu" >
                        <div className="row col-xxl-4 z-1">
                        <li className="nav-item col-xxl-4">
                            <Link className="nav-link ms-2 menu-item title-header my-3" to="/">Trang chủ</Link>
                        </li>
                        <li className="nav-item col-xxl-4">
                            <Link className="nav-link ms-2 menu-item title-header my-3" to="/product">Sản phẩm</Link>
                        </li>
                        <li className="nav-item col-xxl-4">
                            <Link className="nav-link ms-2 menu-item title-header my-3" to="/introduce">Liên hệ</Link>
                        </li>
                        </div>

                      </ul>
                    </div>

            </div>
          </nav>
    </header>
  }
}

export default Header;