import React from "react";
import Carousel from "../../components/Home/Carousel/Carousel";
import Adver from "../../components/Home/Adver/Adver";
import './Body.scss';
import InforCompany from "../../components/Home/InforCompany/InforCompany";
class Body extends React.Component{
    render(){
        return <React.Fragment>
            <div className="col-xxl-9 carousel-0">
                <Carousel />
            </div>
            <Adver />
            <InforCompany />
        </React.Fragment>
    }
}

export default Body;