import React from "react";
import axios from "axios";
import ImageUpload from "../../../../components/ImageUpload/ImageUpload";
import SlideBarAdmin from "../../SlidebarAdmin/SlideBArAdmin";
import NavBarAdmin from "../../NavbarAdmin/NavbarAdmin";
class CreateProduct extends React.Component{

    state = {
        title : "",
        url : "",
        type : 1,
    }

    handleChangeTitle = (e)=>{
        this.setState({title: e.target.value});
    }

    handleChangeUrl = (e) =>{
        this.setState({url: e.target.value});
    }

    handleChangeType = (e) =>{
        this.setState({type : e.target.value})
    }

    updateUrl = (url) =>{
        this.setState({url: url});
    }

    removeAlert = () =>{
        const alert = document.getElementById('alertSuccess');
        alert.classList.add("d-none");
    }

    handleSumbmit = (e) =>{
        const notiName = document.getElementById('notiNameProduct');
        if(this.state.title === ""){
            notiName.classList.remove("d-none");
        }else{
            notiName.classList.add("d-none");
        }

        const notiImage = document.getElementById('notiImageProduct');
        if(this.state.url === ""){
            notiImage.classList.remove("d-none");
        }else{
            notiImage.classList.add("d-none");
        }

        if(this.state.title !== "" && this.state.url !== ""){
            axios.post("http://localhost:8081/sanpham", this.state)
            .then(res => {
                this.setState({data: res.data})
                const alert = document.getElementById('alertSuccess');
                alert.classList.remove("d-none");
            })
            .catch(err => console.log(err));
        }
    }

    render(){
        return <div className="container-fluid">
        <div className="row">
            <SlideBarAdmin />
            <div className="col-md-10 px-0">
                <NavBarAdmin />

                <div className="container-fluid row mx-2 mt-2">
                    <h2 className="mt-2">Thêm sản phẩm</h2>
                    <br />
                    <div className="alert alert-success d-none" id="alertSuccess" role="alert">
                    Thêm sản phẩm thành công!!!
                    </div>
                    <div className="mb-3 col-md-3 mt-2">
                        <label htmlFor="title" className="form-label h4">Tên sản phẩm</label>
                        <input type="text" className="form-control" id="title" placeholder="Tên sản phẩm" onChange={this.handleChangeTitle}/>
                        <p className="text-danger my-0 d-none" id = "notiNameProduct">Chưa nhập tên sản phẩm</p>

                        <label htmlFor="type" className="form-label h4">Loại sản phẩm</label>
                        <select class="form-select" aria-label="Default select example" onChange={this.handleChangeType}>
                            <option selected value="1">Sản phẩm đơn</option>
                            <option value="2">Combo 2</option>
                            <option value="3">Combo 4</option>
                            <option value="4">Combo 8</option>
                            <option value="0">Combo 10</option>
                            <option value="5">Combo 12</option>
                            <option value="6">Combo 24</option>
                        </select>
                    </div>
                    <ImageUpload updateUrlHandler={(url) => this.updateUrl(url)} url1={null} removeAlert = {() => this.removeAlert()} />
                    <p className="text-danger my-0 d-none" id="notiImageProduct">Chưa nhập ảnh</p>
                    <div className="mt-2 mx-1">
                        <button type="submit" className="btn btn-primary" onClick={this.handleSumbmit}>Thêm</button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    } 
}

export default CreateProduct;