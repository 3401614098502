import React from "react";
import './InforCompany.scss';
import banner from "../../../assets/images/carousel/banner5.jpg";
class InforCompany extends React.Component{
    render(){
        return <>
            <div className="infor">
                <div className="col-xxl-12 row">
                    <div className="col-xxl-6">
                        <img src={banner} alt="company" className="centered-img" />
                    </div>
                    <div className="col-xxl-6">
                        <div className="col-xxl-12 pt-1">
                            <p className="trirong-bold m-0 name-company-infor">GIỚI THIỆU CÔNG TY</p>
                        </div>
                        <div className="col-xxl-12">
                            <p className="trirong-regular-italic noti-company">
                            Công ty TNHH YAHON được thành lập vào năm 1997 với 100% vốn Đài Loan. Chúng tôi có kinh nghiệm 
                            nhiều năm trong việc sản xuất và kinh doanh các mặt hàng tiêu dùng nhanh ở thị trường trong và ngoài nước.
                             YAHON luôn cố gắng cung cấp hàng hóa có chất lượng cao cũng như không ngừng phát triển sản phẩm mới nhằm mang 
                             đến cuộc sống tốt hơn cho người tiêu dùng.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default InforCompany;