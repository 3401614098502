import React from "react";
import './Admin.scss';
import NavBarAdmin from "./NavbarAdmin/NavbarAdmin";
import SlideBarAdmin from "./SlidebarAdmin/SlideBArAdmin";


class Admin extends React.Component{    
    render(){
        return <div className="container-fluid">
                <div className="row">
                    <SlideBarAdmin />
                    <div className="col-md-10 px-0">
                        <NavBarAdmin  logout = {this.props.logout} />
                    </div>
                </div>
            </div>
    }
}

export default Admin;