import React from "react";
import Header from "../../views/Header/Header";
import './Home.scss';
import Body from "../../views/Body/Body";
import Footer from "../../views/Footer/Footer";
class Home extends React.Component{
    render(){
        return  <div>
            <div className="circle">
                <div className="content-wrapper">
                    <p className="slogan">Khăn ướt Sunny</p>
                    <p className="slogan">trải nghiệm xứng đáng</p>
                </div>
            </div>
            <Header />
            <Body />
            <Footer />
        </div>
        
        
    }
}
  

export default Home;