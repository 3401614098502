import React from "react";
import Banner from "../../../components/Product/Banner/Banner";
import ListProduct from "../../../components/Product/ListProduct/ListProduct";
import Header from "../../../views/Header/Header";
import Footer from "../../../views/Footer/Footer";

class Product extends React.Component{
    render(){
        return <React.Fragment>
            <Header />
            <Banner />
            <ListProduct />
            <Footer />
        </React.Fragment>;
    }
}

export default Product;