import React from "react";

class NavBarAdmin extends React.Component{
    render(){
        return  <nav className="navbar navbar-light bg-secondary">
                <div className="d-flex flex-row"></div>
                <div className="d-flex flex-row-reverse">
                    <div className="p-2 me-2">
                            <button type="button" className="btn btn-light" onClick={this.props.logout} >Log out</button>
                    </div>
                </div>
            </nav>
    }
}

export default NavBarAdmin;