import React from "react";
import './Adver.scss';
import icon1 from "../../../assets/images/icons/icon_1st_medal.png";
import icon2 from "../../../assets/images/icons/icon_gift.png";
import icon3 from "../../../assets/images/icons/icon_delivery_truck.png";

class Adver extends React.Component{
    render(){
        return <>
            <div className="container-fluid adver">
                <div className="content-wrapper">
                    <h1 className="trirong-regular title-0 text-center">Thế giới khăn ướt - Cửa hàng online</h1>
                    <p className="trirong-extralight title-1">Website bán hàng chính thức của công ty cổ phần Khăn ướt Chiêu Dương</p>
                </div>

                <div className="row col-xxl-12 pt-2">
                    <div className="col-xxl-4">
                        <div className="col-xxl-12 d-flex justify-content-center align-items-center">
                            <img src={icon1} alt="icon1" className="image-icon"/>
                        </div>
                        <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                            <p className="trirong-extralight title-0 text-center">Chất lượng thật - Giá trị thật</p>
                        </div>
                        <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                            <div className="col-xxl-8">
                                <p className="trirong-extralight title-2 text-center">Số lượng sản phẩm và dịch vụ tốt nhất với chủng loại đa dạng, 
                                phong phú sẽ đáp ứng tất cả nhu cầu mua sắm của bạn.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-4">
                        <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                            <img src={icon2} alt="icon2" className="image-icon" />
                        </div>
                        <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                            <p className="trirong-extralight title-0 text-center">Quà tặng theo giá trị đơn hàng</p>
                        </div>
                        <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                            <div className="col-xxl-8">
                                <p className="trirong-extralight title-2 text-center">Tất cả các khách hàng đã mua sắm tại Thế Giới Khăn Ướt đều sẽ được
                                 phần quà theo từng giá trị đơn hàng khác nhau</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-4">
                        <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                            <img src={icon3} alt="icon3" className="image-icon" />
                        </div>
                        <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                            <p className="trirong-extralight title-0">Hỗ trợ giao hàng toàn quốc</p>
                        </div>
                        <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                            <div className="col-xxl-8">
                                <p className="trirong-extralight title-2 text-center">Miễn phí vận chuyển toàn quốc với đơn hàng từ 199.000 trở lên</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    }
}

export default Adver;