import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Home from "../routers/Home/Home";
import Admin from "../routers/Admin/Admin";
import React, { useState,  useEffect } from "react";
import CreateProduct from "../routers/Admin/Body/CreateProduct/CreateProduct";
import UpdateProductWrapper from "../routers/Admin/Body/UpdateProduct.js/UpdateProduct";
import Product from "../routers/Home/Product/Product";
import ProductAdmin from "../routers/Admin/Body/ProductAdmin/ProductAdmin";
import Login from "../routers/Login/Login";
import Introduce from "../routers/Home/Introduce/Introduce";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
  };

  return (
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/product" element={<Product />} />
            <Route path="/introduce" element={<Introduce />} />
            <Route path="/admin"  element={isLoggedIn ? <Admin logout={logout} /> : <Navigate to="/login" />} />
            <Route path="/login" element={!isLoggedIn ? <Login login={login}/> : <Navigate to="/admin" />} />
            <Route path="/admin/product" element={<ProductAdmin />} />
            <Route path="/admin/create_product" element={<CreateProduct />} />
            <Route path="/admin/update_product/:id" element={<UpdateProductWrapper />} />
          </Routes>
        </div>
    </BrowserRouter>

  );
}

export default App;


