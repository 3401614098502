import React from "react";
import './Footer.scss';
import logo from "../../assets/images/logo.png";
class Footer extends React.Component{
    render(){
        return <>
            <footer>
                <div className="col-xxl-12 row">
                    <div className="col-xxl-6 p-3">
                        <p className="trirong-bold name-footer-company">CÔNG TY TNHH TRẦN CHIÊU DƯƠNG</p>
                        <p className="trirong-light infor-company">Địa chỉ: 154/1c Nguyễn Trãi nối dài, P. Lê Bình, Q. Cái Răng, TP. Cần Thơ, Việt Nam</p>
                        <p className="trirong-light infor-company">Điện thoại: 0292 654 6789</p>
                        <p className="trirong-light infor-company">Email: cskh@chieuthy.net</p>
                    </div>

                    <div className="col-xxl-6 d-flex justify-content-center align-items-center">
                        <img src={logo} alt="logo" className="centered-img logo-footer"/>
                    </div>
                </div>
            </footer>
        </>
    }
}

export default Footer;