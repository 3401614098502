import React from "react";
import axios from "axios";

class Login extends React.Component{

    state = {
        username : "",
        password : "",
    }

    handleChangeUsername = (e)=>{
        this.setState({username: e.target.value});
    }

    handleChangePassword = (e) =>{
        this.setState({password: e.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const alertUser = document.getElementById("alertUser"); 
        if(this.state.username === ""){
            alertUser.classList.remove("d-none");
            alertUser.innerHTML = "Chưa nhập tài khoản";
        }else{
            alertUser.classList.add("d-none");
        }
        //
        const alertPass= document.getElementById("alertPass"); 
        if(this.state.password === ""){
            alertPass.classList.remove("d-none");
            alertPass.innerHTML = "Chưa nhập mật khẩu";
        }else{
            alertPass.classList.add("d-none");
        }

        if(this.state.username !== "" && this.state.password !== ""){
            axios.get("http://localhost:8081/taikhoan", { params: this.state })
            .then(res => {
                const message = res.data.Message;
                if(message === "Passwords do not match!"){
                    alertPass.classList.remove("d-none");
                    alertPass.innerHTML = "Mật khẩu không chính xác";
                    return;
                }

                if(message === "Login Success"){
                    this.props.login();
                }
            })
            .catch(err => console.log(err));
        }

    };

    render(){
        return (
            <div className="container mt-5 pt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-5 bg-light">
                        <div className="p-4 p-md-5">
                            <h2 className="text-center mb-4">Login</h2>
                            <form onSubmit={this.handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label h5">Tài khoản</label>
                                    <input type="text" className="form-control" id="username" placeholder="Tài khoản" onChange={this.handleChangeUsername}/>
                                    <div id="alertUser" className="form-text text-danger d-none"></div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label h5">Mật khẩu</label>
                                    <input type="password" className="form-control" id="password" placeholder="Mật khẩu" onChange={this.handleChangePassword}/>
                                    <div id="alertPass" className="form-text text-danger d-none">Tài khoản không chính xác</div>
                                </div>

                                <div className="mb-3 row">
                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="btn btn-primary">Đăng nhập</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default Login;