import React from "react";
import axios from "axios";
import { useParams, Link } from 'react-router-dom';
import SlideBarAdmin from "../../SlidebarAdmin/SlideBArAdmin";
import NavBarAdmin from "../../NavbarAdmin/NavbarAdmin";
import ImageUpload from "../../../../components/ImageUpload/ImageUpload";

class UpdateProduct extends React.Component{

    state = {
        title : "",
        url : "",
        type : 0,
    }

    componentDidMount(){
        const { id } = this.props;
        axios.get("http://localhost:8081/sanpham/" + id)
        .then(res => {
            console.log(res);
            this.setState({title : res.data[0].title == null ? "" : res.data[0].title , url : res.data[0].url == null ? "" : res.data[0].url , type : res.data[0].type })
        })
        .catch(err => console.log(err));
    }

    handleChangeTitle = (e)=>{
        this.setState({title: e.target.value});
    }

    handleChangeType = (e) =>{
        this.setState({type : e.target.value})
    }

    updateUrl = (url) =>{
        this.setState({url: url});
    }

    handleSumbmit = () =>{
        const { id } = this.props;
        axios.put("http://localhost:8081/sanpham_update/" + id, this.state)
        .then(res => {   
            const alert = document.getElementById('alertSuccess');
            alert.classList.remove("d-none");
        })
        .catch(err => console.log(err));
    }

    render(){
        return (
            <div className="container-fluid">
                <div className="row">
                    <SlideBarAdmin />
                    <div className="col-md-10 px-0">
                        <NavBarAdmin />

                        <div className="container-fluid row mx-2 mt-2">
                            <h2 className="mt-2">Sản phẩm</h2>
                            <div class="alert alert-success d-none" id="alertSuccess" role="alert">
                            Câp nhật sản phẩm thành công!!!
                            </div>
                            <div className="mb-3 col-md-3 mt-2">
                                <label htmlFor="title" className="form-label h4">Tên sản phẩm</label>
                                <input type="text" className="form-control" id="title" placeholder="Tiêu đề" onChange={this.handleChangeTitle} value={this.state.title}/>

                                <label htmlFor="type" className="form-label h4">Loại sản phẩm</label>
                                <select class="form-select" aria-label="Default select example" value={this.state.type} onChange={this.handleChangeType}>
                                    <option value="1">Sản phẩm đơn</option>
                                    <option value="2">Combo 2</option>
                                    <option value="3">Combo 4</option>
                                    <option value="4">Combo 8</option>
                                    <option value="0">Combo 10</option>
                                    <option value="5">Combo 12</option>
                                    <option value="6">Combo 24</option>
                                </select>
                            </div>
                            <ImageUpload updateUrlHandler={(url) => this.updateUrl(url)} url1={this.state.url}/>

                            <div className="mt-2 mx-1">
                                <Link to="/admin/product" className="btn btn-primary">Back</Link>
                                <button type="submit" className="btn btn-primary mx-2" onClick={this.handleSumbmit}>Cập nhật</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

function UpdateProductWrapper() {
    const { id } = useParams();
    return <UpdateProduct id={id} />;
  }

export default UpdateProductWrapper;