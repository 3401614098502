import React from "react";
import './Introduce.scss';
import introduce from "../../../assets/images/introduce.jpg"
import iconMap from "../../../assets/images/icons/icon_map.png"
import iconMail from "../../../assets/images/icons/icon_mail.png"
import iconPhone from "../../../assets/images/icons/icon_phone.png"
import iconFacebook from "../../../assets/images/icons/icon_facebook.png"
import Header from "../../../views/Header/Header";
import Footer from "../../../views/Footer/Footer";

class Introduce extends React.Component{
    render(){
        return <React.Fragment>
        <Header />
        <div className="banner-product">
            <div className="col-xxl-12 d-flex justify-content-center align-items-center">
                <img src={introduce}  alt="banner" className="centered-img"/>
            </div>
        </div>

        <div className="container-fluid introduce">
            <div className="col-xxl-12 row">
                <div className="col-xxl-3 col-6">
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center icon-intro">
                        <img src={iconMap} alt="icon1" className="image-icon"/>
                    </div>
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center">
                        <p className="trirong-extralight title-0 text-center m-0">Địa chỉ</p>
                    </div>
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                        <div className="col-xxl-8">
                            <p className="trirong-extralight title-2 text-center">154/1c  Nguyễn Trãi nối dài, P Lê Bình,  Quận Cái Răng, TP Cần Thơ, Việt Nam</p>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-3 col-6">
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center icon-intro">
                        <img src={iconPhone} alt="icon1" className="image-icon"/>
                    </div>
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center">
                        <p className="trirong-extralight title-0 text-center m-0">Số điện thoại</p>
                    </div>
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                        <div className="col-xxl-8">
                            <p className="trirong-extralight title-2 text-center">0292 654 6789</p>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-3 col-6">
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center icon-intro">
                        <img src={iconMail} alt="icon1" className="image-icon email"/>
                    </div>
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center">
                        <p className="trirong-extralight title-0 text-center m-0">Email</p>
                    </div>
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                        <div className="col-xxl-8">
                            <p className="trirong-extralight title-2 text-center">cskh@chieuduong.net</p>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-3 col-6">
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center icon-intro">
                        <img src={iconFacebook} alt="icon1" className="image-icon"/>
                    </div>
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center">
                        <p className="trirong-extralight title-0 text-center m-0">Facebook</p>
                    </div>
                    <div className="col-xxl-12 d-flex justify-content-center align-items-center pt-1">
                        <div className="col-xxl-8">
                            <p className="trirong-extralight title-2 text-center"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </React.Fragment>;
        

    }
}

export default Introduce;