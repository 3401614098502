import React, { useEffect, useState } from "react";
// import { Image } from 'cloudinary-react';
import CheckIcon from "../Icons/IconCheck";
import axios from "axios";

const ImageUpload = ({updateUrlHandler, url1, removeAlert}) => {
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [isShow, setIsShow] = useState(false);

  const uploadImage = async () => {
    setLoading(true);
    if (image) {
      const formData = new FormData();
      formData.append('image', image);
      try {
        const response = await axios.post('http://localhost:8081/uploadImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Image uploaded successfully:', response.data);
        setUrl(response.data);
        updateUrlHandler(response.data);
        setIsShow(true);
        setLoading(false);
      } catch (error) {
        console.error('Error uploading image:', error);
        setLoading(false);
      }
    }
  };

  useEffect(()=>{
    setPreview(url1);
    if(url1 != null)
      setIsShow(true);
  }, [url1])

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setIsShow(false);
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setPreview(reader.result);
      removeAlert();
    };
  };

  const handleResetClick = () => {
    setPreview(null);
    setImage(null);
    setIsShow(false);
  };

  return (
    <div className="h-screen sm:px-8 md:px-16 sm:py-8">
      <div className="mx-auto max-w-screen-lg h-full">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
          <p className="mb-2 font-semibold text-gray-900 flex flex-wrap justify-center h4">
            <span>Ảnh</span>&nbsp;
          </p>
          <input
            id="hidden-input"
            type="file"
            className="hidden btn"
            onChange={handleImageChange}
            accept="image/*"
          />
          <label htmlFor="hidden-input" className="cursor-pointer"> </label>

          <div className="flex justify-center items-center mx-3 my-2 max-w-xs">
            {preview && <img src={preview} alt="preview" className="col-3" />}
          </div>
        </header>
        <div className="flex justify-end pb-8 pt-6 gap-4">
          <button
            onClick={uploadImage}
            className="rounded-sm px-3 py-1 bg-blue-700 hover:bg-blue-500 text-white focus:shadow-outline focus:outline-none disabled:cursor-not-allowed btn btn-success mx-1"
            disabled={!image}
          >
            Upload
          </button>
          <button
            onClick={handleResetClick}
            className="rounded-sm px-3 py-1 bg-red-700 hover:bg-red-500 text-white focus:shadow-outline focus:outline-none btn-danger btn mx-1"
          >
            Xóa
          </button>
          <CheckIcon isShow={isShow} />
        </div>
        {loading ? (
          <div className="flex items-center justify-center gap-2">
            <div className="border-t-transparent border-solid animate-spin rounded-full border-blue-400 border-4 h-6 w-6"></div>
            <span>Processing...</span>
          </div>
        ) : (
          url && (
            <></>
          )
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
