import React from "react";
import { Link } from "react-router-dom";
class SlideBarAdmin extends React.Component{
    render(){
        return  <nav className="col-md-3 col-lg-2 d-md-block bg-light fullscreen-height collapse bg-dark">
            <div className="position-sticky pt-3">
                <div className="d-flex justify-content-center">
                    <p className="h2 text-light">ADMIN</p>
                </div>
                <hr className="sidebar-divider text-white mb-0 mt-3"></hr>
                <div className="d-flex justify-content-center py-3 hover-overlay bg-hover" style={{cursor: "pointer"}}>
                    <p className="h4 text-light my-0">
                    <Link className="h4 text-light my-0" to="/admin/product" style={{textDecoration: "none"}}>Sản phẩm</Link>
                    </p>
                </div>
                <hr className="sidebar-divider text-white mt-0"></hr>
            </div>
        </nav>
    }
}

export default SlideBarAdmin;